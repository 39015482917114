import { ActionDropDown, ButtonFrame, CardSearch, ConfirmationModal, ExportData, PageBodyHeader, ReportModal, Table, TableHeadElement, UpdateSohModal } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { checkStoreByStoreIdUserId, deleteProductOrder, fetchProductOrderParentListReport, fetchProductParentOrder } from "../../action/generalFunc";
import { toast } from "react-toastify";
// import { IoAdd } from "react-icons/io5";

const StaffProductList = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [openReportModal, setOpenReportModal] = useState<boolean>(false);
    const [storeName, setStoreName] = useState<string>("");
    const [vendorName, setVendorName] = useState<string>("");
    const [exportType, setExportType] = useState<string | null>(null);
    const [rangeParam, setRangeParam] = useState<string | null>("");
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
    const [itemToDelete, setItemToDelete] = useState<generalObj | null>(null);
    const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
    const [itemToUpdate, setItemToUpdate] = useState<generalObj | null>(null);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();
    const { id, store_id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id || !store_id) return;
        (async () => {
            const queryParameters = new URLSearchParams(window.location.search)
            setModalLoading(true)
            let result = await checkStoreByStoreIdUserId(account.token, store_id, id);
            if (!result) {
                window.history.back();
            }else {
                setStoreName(`${result.data.store_data.name} - ${result.data.store_data.organization}`)
                setVendorName(`${result.data.vendor_data.username}`)
            }
            (async () => {
                let order_result;
                if (queryParameters.size > 0 && queryParameters.get('range')) {
                    let range = queryParameters.get('range');
                    order_result = await fetchProductParentOrder(account.token, store_id, id, range)
                    setRangeParam(range);
                }else {
                    order_result = await fetchProductParentOrder(account.token, store_id, id)
                }
                if (!order_result) {
                    window.history.back();
                }
                setProductList(order_result);
                setModalLoading(false)
            })();
            
        })();
    }, [account, setModalLoading, id, store_id]);

    const onSearch = (val:string) => {
        let new_val = val.toLowerCase()
        setProductList((prevProductList) => {
            return (
                prevProductList.filter((item) => {
                    if (item.product.toLowerCase().includes(new_val) || new_val.includes(item.size) || new_val.includes(item.quantity) || new_val.includes(item.open_stock) || new_val.includes(item.close_stock) || new_val.includes(item.ros) || new_val.includes(item.quantity_sold)) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const processExport = (export_type:string) => {
        setExportType(export_type);
        setOpenReportModal(true);
    }

    const onDelete = async () => {
        if (!account || !account.isAuthenticated || !itemToDelete || !id || !store_id) return;
        setModalLoading(true);
        let api_data:generalObj = {
            product_id: itemToDelete.id,
            vendor: id,
            store: store_id
        };

        let result = await deleteProductOrder(account.token, api_data);
        if (result) {
            toast(result);
            setProductList((prevProductList) => {
                return (
                    prevProductList.filter((item) => item.id !== itemToDelete.id)
                )
            });
        }
        setItemToDelete(null);
        setOpenDeleteConfirmation(false)
        setModalLoading(false)
        window.location.reload();
    }

    const generateReport = async (val:generalObj) => {
        if (!exportType || !id || !store_id || !account || !account.isAuthenticated) return;

        setModalLoading(true);
        let date_from_obj = new Date(val.date_from);
        let date_to_obj = new Date(val.date_to);
        let param:generalObj = {
            type: exportType,
            date_from: date_from_obj.toISOString(),
            date_to: date_to_obj.toISOString(),
            store: store_id,
            vendor: id
        };
        
        if (rangeParam) {
            param.range = rangeParam;
        }

        let result = await fetchProductOrderParentListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_order_parent_list.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            setExportType(null);
            setOpenReportModal(false);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader 
                title="Product Detail Report List" 
                desc="product details"
                inlineChild={<ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => navigate(`${UrlTypes.STAFF_STORE_MENU}${id}/${store_id}/`)} />} 
            >
                {/* <button className="product_create_button" onClick={() => navigate(UrlTypes.ADMIN_PRODUCT_ADD)}>
                    <span><IoAdd /></span>
                    <span>Create Product</span>
                </button> */}
                <></>
            </PageBodyHeader>

            <Table table_head={["Product", "Size", "SOH", "Open Stock", "Closing Stock", {
                element: <TableHeadElement title="Qty Sold" current_value={rangeParam} />
            }, {
                element: <TableHeadElement title="ROS" current_value={rangeParam} />
            }, "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={<ExportData export_type={(val) => processExport(val)} />}
            headerStyle={{marginBottom: "44px"}}
            containerCardStyle={{minHeight:"200px", paddingBottom:'50px'}}
            table_title={`Product report ${vendorName.toUpperCase()} in ${storeName}`}
            >
                <>
                    {productList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            <tr key={index}>
                                <td>
                                    <div className="contentWithImg">
                                        <span><img src={item.image} alt={item.product} /></span>
                                        <span>{item.product}</span>
                                    </div>
                                </td>
                                <td>{item.size}</td>
                                <td>{item.quantity}</td>
                                <td>{item.open_stock}</td>
                                <td>{item.close_stock}</td>
                                <td>{item.quantity_sold}</td>
                                <td>{item.ros}</td>
                                <td>
                                    <div className="edit__delete_action">
                                        <button onClick={() => navigate(`${UrlTypes.STAFF_PRODUCT_SUB_LIST}${id}/${store_id}/${item.id}/`)} className={`status__container completed`} style={{width:"60px", padding:"5px", height:"auto"}}>
                                            View
                                        </button>
                                        <ActionDropDown>
                                            <ul>
                                                <li>
                                                    <button onClick={() => {setItemToDelete(item); setOpenDeleteConfirmation(true)}}>Delete</button>
                                                </li>
                                                <li>
                                                    <button onClick={() => {setItemToUpdate(item); setOpenUpdateModal(true)}}>Update SOH</button>
                                                </li>
                                            </ul>
                                        </ActionDropDown>
                                    </div>
                                </td>
                            </tr>
                        )
                    ))}
                </>
            </Table>
            
            <ReportModal visible={openReportModal} modalClose={() => setOpenReportModal(false)} onAdded={(val) => generateReport(val)}  />
            {itemToDelete && <ConfirmationModal visible={openDeleteConfirmation} title={"Delete Confirmation"} description={`Do you want to delete Product ${itemToDelete.product}?`} closeText="No" submitText="Yes" modalClose={() => {setItemToDelete(null); setOpenDeleteConfirmation(false)}} onSubmit={() => onDelete()} />}
            {itemToUpdate && <UpdateSohModal visible={openUpdateModal} cur_soh={itemToUpdate} modalClose={() => {setItemToUpdate(null); setOpenUpdateModal(false)}} onAdded={() => window.location.reload()} />}
        </>
    )
}

export default StaffProductList;